
import './App.css';
import Navbar from './Components/Navbar';

import Carousels from './Components/Carousels';
import Cardsection from './Components/Cardsection';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import Appointment from './Components/Appointment';
import Services from './Components/Services';
import Feedback from './Components/Feedback';
import Footer from './Components/Footer';

function App() {
  return (
    <>
    <Navbar/>
    <Carousels/>
    <Cardsection/>
    <Appointment/>
    <Services/>
    {/* <Feedback/> */}
    <Footer/>
    </>
  );
}

export default App;
