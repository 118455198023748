import Carousel from 'react-bootstrap/Carousel'
import "../styles/styles.css";
import "../styles/Responsive.css";
import image1 from '../images/image1.png';
import image2 from '../images/image2.png';
import image3 from '../images/image3.webp';

function IndividualIntervalsExample() {
  return (
    <Carousel>
     <Carousel.Item interval={2000} className="carousel-item-background">
      <div className='container xs-banner-container-parent'>
  <div className="row">
    
    <div className=" banner-column col-sm-12 col-md-6 col-lg-6">
      {/* Content for the second column */}
      <div className='wow'>
          <h1>An Attractive Smile Makes A Lasting Impression !</h1>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit nobis cum corrupti quia blanditiis corporis distinctio minima ut dolore eaque soluta hic dolorem deleniti architecto autem officiis, alias eum voluptatibus.</p>
      </div>
      {/* <div className='buton'>
      <button class="animated-button">
  <svg xmlns="http://www.w3.org/2000/svg" class="arr-2" viewBox="0 0 24 24">
    <path
      d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
    ></path>
  </svg>
  <span class="text">Book Now</span>
  <span class="circle"></span>
  <svg xmlns="http://www.w3.org/2000/svg" class="arr-1" viewBox="0 0 24 24">
    <path
      d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
    ></path>
  </svg>
</button>
 </div> */}
    </div>
    <div className="col-sm-12 col-md-6 col-lg-6">
      <img src={image1} alt="" className='car-img' />
    </div>
  </div>
  </div>
</Carousel.Item>
<Carousel.Item interval={500} className="carousel-item-background">
      <div className='container xs-banner-container-parent'>
  <div className="row">
      <div className=" banner-column col-sm-12 col-md-6 col-lg-6">
      {/* Content for the second column */}
      <div className='wow'>
          <h1>An Attractive Smile Makes A Lasting Impression !</h1>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit nobis cum corrupti quia blanditiis corporis distinctio minima ut dolore eaque soluta hic dolorem deleniti architecto autem officiis, alias eum voluptatibus.</p>
      </div>
      {/* <div className='buton'>
      <button class="animated-button">
  <svg xmlns="http://www.w3.org/2000/svg" class="arr-2" viewBox="0 0 24 24">
    <path
      d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
    ></path>
  </svg>
  <span class="text">Book Now</span>
  <span class="circle"></span>
  <svg xmlns="http://www.w3.org/2000/svg" class="arr-1" viewBox="0 0 24 24">
    <path
      d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
    ></path>
  </svg>
</button>




      </div> */}
    </div>
    <div className="col-sm-12 col-md-6 col-lg-6">
      <img src={image2} alt="" className='car-img' />
    </div>
  </div>
  </div>
</Carousel.Item>
<Carousel.Item interval={500} className="carousel-item-background">
      <div className='container xs-banner-container-parent'>
  <div className="row">
      <div className=" banner-column col-sm-12 col-md-6 col-lg-6">
      {/* Content for the second column */}
      <div className='wow'>
          <h1>An Attractive Smile Makes A Lasting Impression !</h1>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit nobis cum corrupti quia blanditiis corporis distinctio minima ut dolore eaque soluta hic dolorem deleniti architecto autem officiis, alias eum voluptatibus.</p>
      </div>
      {/* <div className='buton'>
      <button class="animated-button">
  <svg xmlns="http://www.w3.org/2000/svg" class="arr-2" viewBox="0 0 24 24">
    <path
      d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
    ></path>
  </svg>
  <span class="text">Book Now</span>
  <span class="circle"></span>
  <svg xmlns="http://www.w3.org/2000/svg" class="arr-1" viewBox="0 0 24 24">
    <path
      d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
    ></path>
  </svg>
</button>




      </div> */}
    </div>
    <div className="col-sm-12 col-md-6 col-lg-6">
      <img src={image3} alt="" className='car-img' />
    </div>
  </div>
  </div>
</Carousel.Item>
    </Carousel>
  );
}

export default IndividualIntervalsExample;