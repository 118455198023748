import React from "react";
import "../styles/styles.css";
import "../styles/Responsive.css";
import dr_payal from "../images/dr_payal.png";
import footerbg1 from "../images/footerbg1.webp";

function Footer() {
  return (
    <footer className="footer" id="footer">
      <div className="footer-main-area footer_img">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-6 col-md-6">
              <div className="widget footer-widget">
                <img
                  src={dr_payal}
                  alt=""
                  className="mrb-20"
                  style={{ width: "250px", height: "60px" }}
                />
                <address className="mrb-25">
                  <div className="mrb-10 mt-4">
                    <a href="#" className="text-light-gray  decor">
                      <i className="fas fa-phone-alt mrr-10"></i>(+91)
                      9730032562
                    </a>
                  </div>
                  <div className="mrb-10">
                    <a href="#" className="text-light-gray  decor">
                    <i className="fa-solid fa-location-dot mrr-10"></i>
                     Flat No. 2, Lunkad Dreamland, near Datta Mandir Square, Clover
                      Park, Viman Nagar, Pune, Maharashtra 411014
                    </a>
                  </div>
                  {/* <div className="mrb-0">
                    <a href="#" className="text-light-gray  decor">
                      <i className="fas fa-globe mrr-10"></i>
                    </a>
                  </div> */}
                </address>
                <ul class="social-list">
                  <li>
                    <a href="#">
                      <i class="fab fa-facebook"></i>
                    </a>
                  </li>
                  {/* <li>
                    <a href="#">
                      <i class="fab fa-twitter"></i>
                    </a>
                  </li> */}
                  <li>
                    <a href="#">
                      <i class="fab fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {/* <div className="col-xl-3 col-lg-6 col-md-6">
                    <div className="widget footer-widget">
                        <h5 className="widget-title text-light mrb-30">Quick Links</h5>
                        <ul className="footer-widget-list">
                            <li><a href="index.html">Cleaning</a></li>
                            <li><a href="about.html">Crowns & bridges</a></li>
                            <li><a href="#">Available Lab</a></li>
                            <li><a href="egoverance.html">Root canals</a></li>
                            <li><a href="tender.html">Cosmetic dentistry</a></li>
                            <li><a href="contact-us.html">Dental implants</a></li>
                        </ul>
                    </div>
                </div> */}
            {/* <div className="col-xl-3 col-lg-6 col-md-6">
                    <div className="widget footer-widget">
                        <h5 className="widget-title text-white mrb-30">About Us</h5>
                        <ul className="footer-widget-list">
                            <li><a href="about.html">Make An Appointment</a></li>
                            <li><a href="history.html">Faq</a></li>
                            <li><a href="#">Pricing</a></li>
                            <li><a href="#">Privacy Policy</a></li>
                            <li><a href="#">Terms & Conditions</a></li>
                            <li><a href="#">Cleaning Facilities</a></li>
                        </ul>
                    </div>
                </div> */}

                       
            <div className="col-xl-3 col-lg-6 col-md-6">
              <div className="widget footer-widget">
                <h5 className="widget-title text-light mrb-30">Clinic Hours</h5>
                <ul className="footer-widget-list-3">
                  <li>
                    <span>Mon-Thu</span>
                    <span className="open-time">9:00 AM - 7:00 PM</span>
                  </li>
                  <li>
                    <span>Friday - </span> <span>9:00 AM - 6:00 PM</span>
                  </li>
                  <li>
                    <span>Saturday - </span> <span>Opens</span>
                  </li>
                  <li>
                    <span>Sunday </span> <span>Opens</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-xl-3 col-lg-6 col-md-12 col-sm-12'>
                        <div className="loc-map"> 
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.1916331862226!2d73.91342857386066!3d18.565397267806084!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c1253f19244d%3A0x7b47f8eb0f0c9ee3!2sDr.%20Payal&#39;s%20Family%20Dental%20Care!5e0!3m2!1sen!2sin!4v1711546853201!5m2!1sen!2sin" width="600" height="300" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className="location-map"></iframe>
                       </div>
                     </div>  
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
