import React from "react";
import '../styles/styles.css';
import "../styles/Responsive.css";
import about_img from '../images/about_img.png';
import pattern2 from '../images/pattern2.png';
function BorderExample() {
  return (
    <section className="about-area about-p pb-120 pt-120 p-relative fix" id="aboutus">
			<div className="container">
			<div className="pattern-layer" style={{backgroundImage: `url(${pattern2})`}}></div>
				<div className="row">
					<div className="col-lg-6 col-md-6 col-sm-12">
						<div className="s-about-img p-relative  wow fadeInLeft animated " data-animation="fadeInLeft" data-delay=".4s">
							<img src={about_img} alt="" className="about_img" />
						</div>
					</div>
					<div className="col-lg-6 col-md-6 col-sm-12">
						<div className="about-content s-about-content  wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s">
						<div className="about-title second-title pb-25">
							<h3>About Us</h3>
							<h2>We Care For Your Dental Health</h2>
						</div>
						<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint inventore, suscipit officia necessitatibus rerum harum, distinctio et exercitationem voluptates incidunt, provident hic commodi? Dolore dolores ut consequuntur amet! Nostrum, maxime?</p>
						<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat dignissimos soluta expedita doloribus error fuga quod sapiente saepe amet ipsa! Repellendus quos est id autem eaque cupiditate, numquam deleniti asperiores.
						</p>
						</div>
					</div>
				</div>
			</div>
	</section>
  );
}

export default BorderExample;