// import React from "react";
import "../styles/styles.css";
import "../styles/Responsive.css";
import dr_payal from "../images/dr_payal.png";
import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

function Navbar1() {
  // const sideNavBar = () => {
  //   // document.getElementsByClassName('side-panel-content').right = '0px';
  //   alert(document.getElementsByclassNameNameName('side-panel-content').style)
  // };
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidePanel = () => {
    setIsOpen(!isOpen);
  };
  const handleScrollToTimings = () => {
    const timingsSection = document.getElementById('timingsSection');
    timingsSection.scrollIntoView({ behavior: 'smooth' });
  };
  const handle2 = () => {
    const timingsSection = document.getElementById('aboutus');
    timingsSection.scrollIntoView({ behavior: 'smooth' });
  };
  const handle3 = () => {
    const timingsSection = document.getElementById('ourservices');
    timingsSection.scrollIntoView({ behavior: 'smooth' });
  };
  const handle4 = () => {
    const timingsSection = document.getElementById('footer');
    timingsSection.scrollIntoView({ behavior: 'smooth' });
  };


  return (
    <>
      <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
        <Navbar.Brand href="#home">
          <img src={dr_payal} alt="" className="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className="toggle-btn" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto nav_bar">
            <Nav.Link href="#features">
              Home
            </Nav.Link>
            <Nav.Link href="#pricing" onClick={handle2}>
              About Us
            </Nav.Link>
            <Nav.Link href="#pricing" onClick={handleScrollToTimings} >
              Our Timings
            </Nav.Link>
           
            <Nav.Link href="#pricing"  onClick={handle3}>
             Services
            </Nav.Link>
            <Nav.Link href="#pricing"  onClick={handle4}>
              Contact Us
            </Nav.Link>
          </Nav>
          <Nav></Nav>
          <div className="other-options align-items-center d-flex">
           
             
              {/* <button type="button" className="search-bar">
                <i className="fa-solid fa-magnifying-glass"></i>
              </button> */}

            <div className="option-item">
              <div className="contact-item">
                  <div className="phn-no">
                    <i className="fa-solid fa-phone"></i>
                    <span>Phone Number</span>
                    <a href="/">+91 9730032562</a>
                  </div>
                </div>
            </div>
            <div className="option-item">
            <div className="side-panel side-panel-trigger text-right d-none d-lg-block" onClick={toggleSidePanel}>
						  <span className="bar1"></span>
						  <span className="bar2"></span>
						  <span className="bar3"></span>
					</div>
              </div>
          </div>
          <div className={`side-panel-content ${isOpen ? "open" : ""}`}>
        <div className="close-icon">
          <button onClick={toggleSidePanel}>
            <i class="fa-solid fa-x"></i>
          </button>
        </div>
        <div class="side-panel-logo mrb-30">
          <a href="index.html">
            <img src={dr_payal} alt="" className="side-logo" />
          </a>
        </div>
        <div className="side-info mrb-30">
          <div className="side-panel-element mrb-25">
            <h4 className="mrb-10">Contact Info</h4>
            <ul className="list-items">
              <li>
                <span className="fa fa-map-marker-alt mrr-10 text-primary-color"></span>
                Flat No. 2, Lunkad Dreamland, near Datta Mandir Square, Clover
                Park, Viman Nagar, Pune, Maharashtra 411014
              </li>
              {/* <li>
                <span className="fas fa-envelope mrr-10 text-primary-color"></span>
                example@stem.com
              </li> */}
              <li>
                <span className="fas fa-phone-alt mrr-10 text-primary-color"></span>
                (+91) 9730032562
              </li>
            </ul>
          </div>
          <h4 class="mrb-15">Social List</h4>
          <ul class="social-list mx-35">
            <li>
              <a href="#">
                <i class="fab fa-facebook"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i class="fab fa-instagram"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

export default Navbar1;
