import React from 'react';
import '../styles/styles.css';
import '../styles/flaticon_dolt.css';
import '../styles/remixicon.css';
import '../styles/Responsive.css';

function Services() {
  return (
    <section className='home-service sec-pdd' id='ourservices'>
        <div className='container'>
            <div className='service-title text-center'>
                <h3>We Provide Different Type Of Dental Care <br /><span>Services</span> </h3>
                <p>Lorem ipsum is a dummy text it will use for subtitle here</p>
            </div>
         <div className='service-box-wrap'>   
        <div className='row'>
            <div class="col-md-4 col-sm-12">
					<div class="single-service-home">
						<div class="icon-box">
							<div class="inner-box">
							<i class="flaticon-tooth-4"></i>
							</div>
						</div>
						<div class="content">
							<h3>Cleanings</h3>
							<p className='ser-p'>There are many variations of lorem passagei of Lorem Ipsum available but the majority have </p>
						</div>
					</div>
				</div>
                <div class="col-md-4 col-sm-12">
					<div class="single-service-home">
						<div class="icon-box">
							<div class="inner-box">
							<i class="flaticon-tooth-1"></i>
							</div>
						</div>
						<div class="content">
							<h3>Crowns & Bridges</h3>
							<p className='ser-p'>There are many variations of lorem passagei of Lorem Ipsum available but the majority have </p>
							
						</div>
					</div>
				</div>
                <div class="col-md-4 col-sm-12">
					<div class="single-service-home">
						<div class="icon-box">
							<div class="inner-box">
							<i class="flaticon-tool"></i>
							</div>
						</div>
						<div class="content">
							<h3>Available Lab</h3>
							<p className='ser-p'>There are many variations of lorem passagei of Lorem Ipsum available but the majority have </p>
							
						</div>
					</div>
				</div>
                <div class="col-md-4 col-sm-12">
					<div class="single-service-home item-margin">
						<div class="icon-box">
							<div class="inner-box">
							<i class="flaticon-tooth-1"></i>
							</div>
						</div>
						<div class="content">
							<h3>Root Canals</h3>
							<p className='ser-p'>There are many variations of lorem passagei of Lorem Ipsum available but the majority have </p>
							
						</div>
					</div>
				</div>
                <div class="col-md-4 col-sm-12">
					<div class="single-service-home item-margin">
						<div class="icon-box">
							<div class="inner-box">
							<i class="flaticon-tooth-2"></i>
							</div>
						</div>
						<div class="content">
							<h3>Cosmetic Dentistry</h3>
							<p className='ser-p'>There are many variations of lorem passagei of Lorem Ipsum available but the majority have </p>
							
						</div>
					</div>
				</div>
                <div class="col-md-4 col-sm-12">
					<div class="single-service-home item-margin">
						<div class="icon-box">
							<div class="inner-box">
							<i class="flaticon-tooth-4"></i>
							</div>
						</div>
						<div class="content">
							<h3>Dental Implants</h3>
							<p className='ser-p'>There are many variations of lorem passagei of Lorem Ipsum available but the majority have </p>
						
						</div>
					</div>
				</div>
            </div>
            </div>
            
        </div>
    </section>
  )
}

export default Services
