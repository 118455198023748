import React from 'react'
import '../styles/styles.css';
import '../styles/Responsive.css';


function Appointment() {
  return (
   <section className='appointment-section bg-f' id='timingsSection'>
        <div className='container'>
            <div className='title-box'><h2>With access to 24 hour emergency assistance, you can continue to help others.</h2></div>
            <div className='inner-section'>
            <div className='row'>
              <div className='col-lg-8 col-md-12 col-sm-12'></div>
                <div className='col-lg-8 col-md-12 col-sm-12'>
                    <div className='calender-column'>
                        <div className='inner-column'>
                            <div className='calender-title'>
                                    <div className='title'>Need an Emergency ? </div>
                                    <h3>Book An <span class="theme_color">Appointment</span></h3>
                                </div>
                                <div className='table-responsive'>
                                <table class="table">
                                   <thead>
                                        <tr>
                                      <th>Mon <br /></th>
                                      <th>Tue <br /></th>
                                      <th>Wed  <br /></th>
                                      <th>Thu  <br /></th>
                                      <th>Fri  <br /></th>
                                    </tr>
                                  </thead>
                                                      <tbody>
                                                        <tr>
                                                          <td>08:30</td>
                                                          <td>08:30</td>
                                                          <td>09:00</td>
                                                          <td>08:30</td>
                                                          <td>08:30</td>
                                                        </tr>
                                                        <tr>
                                                          <td>08:30</td>
                                                          <td>08:30</td>
                                                          <td>09:00</td>
                                                          <td>08:30</td>
                                                          <td>08:30</td>
                                                        </tr>
                                                        <tr>
                                                          <td>08:30</td>
                                                          <td>08:30</td>
                                                          <td>09:00</td>
                                                          <td>08:30</td>
                                                          <td>08:30</td>
                                                        </tr>
                                                        <tr>
                                                          <td>08:30</td>
                                                          <td>08:30</td>
                                                          <td>09:00</td>
                                                          <td>08:30</td>
                                                          <td>08:30</td>
                                                        </tr>
                                                      </tbody>
                                                    </table> 
                                                    </div>   
                        </div>
                            
                        </div> 
                           
                    </div>
                </div>
        </div>
        </div>
       
   </section>
  )
}

export default Appointment
